import React from 'react';
import '../App.css';
import '../css/main.scss';
import '../css/_variables.scss';

function Overview() {
    return (
        <section id="overview" className="my-5">
            <h2 className="text-center">Overview</h2>
            <p>
                At Sweet AI, we take a tailored, consultative approach to problem-solving by first working with you to identify and define your business’s unique challenges. Through collaborative discovery sessions, we connect these challenges to our advanced suite of Generative AI technologies, crafting targeted strategies that deliver real impact.
            </p>
            <p>
                For situations where existing solutions are insufficient, Sweet AI leverages the adaptability of Generative AI to design custom enterprise-grade models. These tailored solutions align precisely with your organizational goals, ensuring every recommendation is actionable, scalable, and results-driven.
            </p>
            <p>
                Partner with Sweet AI to redefine your challenges and unlock the full potential of your business.
            </p>
        </section>
    );
}

export default Overview;