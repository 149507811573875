import React from 'react';
import '../css/CoverSection.scss'; // Assuming styles are in a separate CSS file

function CoverSection() {
    return (
        <div className="cover position-relative">
            {/* Video Background */}
            <video autoPlay muted loop>
                <source src="videos/12707701_1920_1080_30fps.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Purple-Pink Overlay */}
            <div className="overlay"></div>

            {/* Content */}
            <div className="content text-center">
                <h1>Sweet AI</h1>
                <p>Unlock the Full Potential of Your Business with Generative AI</p>
                <div className="d-flex justify-content-center gap-3">
                      <a href="#overview" className="btn btn-secondary">Learn More</a>
                    <a href="#contact" className="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    );
}

export default CoverSection;