import React from 'react';
import './App.css';
import './css/main.scss';
import './css/_variables.scss';
import CoverSection from './components/CoverSection';
import Overview from './components/Overview';
import ValueProps from './components/ValueProps';
import Services from './components/Services';
import CaseStudies from './components/CaseStudies';
import Footer from './components/Footer';


function App() {
  return (
    <div>
        <CoverSection />
        <main className="container">
            <Overview />
            <ValueProps />
            <Services />
            <CaseStudies />
        </main>
        <Footer />
    </div>
  );
}

export default App;
