import React from 'react';
import '../App.css';
import '../css/main.scss';
import '../css/_variables.scss';

function Services() {
    return (
        <section id="services" className="my-5">
            <h2 className="text-center">Services</h2>
            <div className="row">
                <div className="col-md-6">
                    <ul>
                        <li><strong>Data Mining and Solutions for E-Commerce:</strong> Analyze data to optimize sales and operations.</li>
                        <li><strong>Store Automation:</strong> Automate product categorization and recommendations.</li>
                        <li><strong>Video Summarization:</strong> Condense content into concise summaries.</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <ul>
                        <li><strong>Social Media Automation:</strong> Automate content creation and scheduling.</li>
                        <li><strong>Task Simplification:</strong> Streamline repetitive workflows.</li>
                        <li><strong>AI-Generated Similar Images:</strong> Create visually similar images for marketing purposes.</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Services;