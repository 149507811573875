import React from 'react';
import '../App.css';
import '../css/main.scss';
import '../css/_variables.scss';

function ValueProps() {
    return (
        <section id="value-props" className="my-5">
            <h2 className="text-center">Value Propositions</h2>
            <ul className="list-group">
                <li className="list-group-item">
                    <strong>Tailored Solutions for Complex Challenges:</strong> Customized approaches that drive meaningful outcomes.
                </li>
                <li className="list-group-item">
                    <strong>Generative AI Excellence:</strong> Unlock value across key business areas with transformative AI.
                </li>
                <li className="list-group-item">
                    <strong>Deep Industry Expertise:</strong> Solutions that are as informed as they are innovative.
                </li>
                <li className="list-group-item">
                    <strong>Seamless Integration and ROI Focus:</strong> Smooth integration and measurable returns on investment.
                </li>
                <li className="list-group-item">
                    <strong>Future-Ready Innovation:</strong> Stay competitive in an AI-driven world.
                </li>
            </ul>
        </section>
    );
}

export default ValueProps;