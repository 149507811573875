import React from 'react';
import '../App.css';
import '../css/main.scss';
import '../css/_variables.scss';

function CaseStudies() {
    return (
        <section id="case-studies" className="my-5">
            <h2 className="text-center">Case Studies</h2>
            <ul className="list-group">
                <li className="list-group-item">
                    <strong>Social Media Automation:</strong> Streamlined content creation and scheduling.
                </li>
                <li className="list-group-item">
                    <strong>Store Video Summarization:</strong> Enhanced training efficiency for Launch Cart.
                </li>
                <li className="list-group-item">
                    <strong>Video Generation:</strong> Accelerated video content creation for Targus.
                </li>
                <li className="list-group-item">
                    <strong>Data Mining for Product Selection:</strong> Optimized product selection for eBay.
                </li>
                <li className="list-group-item">
                    <strong>Image Generation:</strong> Delivered copyright-free image tools for NoMoreCopyright.com.
                </li>
            </ul>
        </section>
    );
}

export default CaseStudies;